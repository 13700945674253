export default {
  path: '/project',
  component: () => import(/* webpackChunkName: 'project' */'@/views/project/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'myproject',
      component: () => import(/* webpackChunkName: 'myproject' */'@/views/project/index')
    },
    {
      path: '/project/role',
      name: 'prorole',
      component: () => import(/* webpackChunkName: 'prorole' */'@/views/project/set/role')
    },
    {
      path: '/project/workflow',
      name: 'proworkflow',
      component: () => import(/* webpackChunkName: 'proworkflow' */'@/views/project/set/workflow/index')
    },
    {
      path: '/project/workflow/AddOrEdit',
      name: 'AddOrEditFlow',
      component: () => import(/* webpackChunkName: 'AddOrEditFlow' */'@/views/project/set/workflow/and-edit-workflow')
    },
    {
      path: '/project/milestone',
      name: 'milestone',
      component: () => import(/* webpackChunkName: 'milestone' */'@/views/project/set/milestone/index')
    },
    {
      path: '/project/milestone/setting',
      name: 'milestoneSetting',
      component: () => import(/* webpackChunkName: 'milestoneSetting' */'@/views/project/set/milestone/setting')
    }
    ,
    {
      path: '/project/reportTemplate',
      name: 'reportTemplate',
      component: () => import(/* webpackChunkName: 'reportTemplate' */'@/views/project/set/reportTemplate')
    }
    ,
    {
      path: '/project/setMail',
      name: 'setMail',
      component: () => import(/* webpackChunkName: 'setMail' */'@/views/project/set/setMail')
    },
    {
      path: '/project/task',
      name: 'task',
      component: () => import(/* webpackChunkName: 'task' */'@/views/project/set/task')
    },
    {
      path: '/project/phase',
      name: 'phase',
      component: () => import(/* webpackChunkName: 'phase' */'@/views/project/set/phase')
    },
    {
      path: '/project/monthReport/pm',
      name: 'pm',
      component: () => import(/* webpackChunkName: 'phase' */'@/views/project/monthReport/pm')
    },
    {
      path: '/project/monthReport/pml',
      name: 'pml',
      component: () => import(/* webpackChunkName: 'phase' */'@/views/project/monthReport/pml')
    }
  ]
}