import { get, post } from './http'
import qs from 'qs'


// 获取任务列表
export const getListByTask = data => get(`/v1/crm/task/list?${qs.stringify(data)}`)

// 项目管理-里程碑-项目任务保存
export const taskSaveCrm = data => post(`/v1/crm/task/save`, data)

// 获取职位列表
export const getListByPosition = () => get(`/v1/crm/position/list`)

// 单价类型
export const getPriceType = () => get(`/v1/crm/position/priceType`)

// 获取模版单位列表
export const getUnit = () => get(`/v1/crm/common/unit`)

// 获取模版列表
export const getListByTemplate = () => get(`/v1/crm/template/list`)

// 新建模板
export const addTemplate = data => post(`/v1/crm/template/add`, data)

// 修改模板
export const updateTemplate = data => post(`/v1/crm/template/update`, data)

// 保存模板内容
export const saveTemplate = data => post(`/v1/crm/template/save`, data)

// 获取模版内容
export const getTemplate = data => get(`/v1/crm/template/getTemp?${qs.stringify(data)}`)

// 获取我的报价列表
export const getListByQuote = data => get(`/v1/crm/quote/list?${qs.stringify(data)}`)

// 获取业务机会
export const getBusinessList = data => get(`/v1/crm/quote/businessList?${qs.stringify(data)}`)

//报价基础信息保存 
export const saveBaseInfo = data => post(`/v1/crm/quote/saveBaseInfo`, data)

// 根据id获取报价基础信息
export const getQuoteInfo = data => get(`/v1/crm/quote/getQuoteInfo?${qs.stringify(data)}`)

// 报价任务分配员工保存
export const saveQuoteTask = data => post(`/v1/crm/quote/saveQuoteTask`, data)

// 根据报价ID获取已分配员工
export const getQuoteTask = data => get(`/v1/crm/quote/getQuoteTask?${qs.stringify(data)}`)

// 获取模版内容
export const getTemp = data => get(`/v1/crm/template/getTemp?${qs.stringify(data)}`)

// 报价详细信息保存
export const saveQuoteInfo = data => post(`/v1/crm/quote/saveQuoteInfo`, data)

// 获取部门列表
export const getDepartment = () => get(`/v1/crm/department`)

// 获得组织架构所有人员
export const getAllUser = () => get(`/v1/allUser`)

// 新增部门保存
export const departmentSave = data => post(`/v1/crm/department/save`, data)

// 部门删除
export const departmentDelete = data => post(`/v1/crm/department/delete`, data)

// 获取职位列表，单价和成本
export const getPosition = () => get(`/v1/crm/position`)

// 职位保存
export const PositionSave = data => post(`/v1/crm/position/save`, data)

// 职位删除
export const PositionDelete = data => post(`/v1/crm/position/delete`, data)

// 获取部门分配了哪些员工
export const departmentMember = data => get(`/v1/crm/department/member?${qs.stringify(data)}`)

// 给部门分配员工
export const departmentSaveDepartmentMember = data => post(`/v1/crm/department/saveDepartmentMember`, data)

// 获取报价内容版本
export const getQuoteVersion = data => get(`/v1/crm/quote/getQuoteVersion?${qs.stringify(data)}`)

// 删除报价子任务
export const deleteQuoteTask = data => post(`/v1/crm/quote/deleteQuoteTask`, data)

// 带版本的报价列表
export const quoteTotalList = () => get(`/v1/crm/quote/quoteTotalList`)

// WBS报价列表
export const wbslist = data => get(`/v1/crm/wbs/list?${qs.stringify(data)}`)

// WBS报价模板
export const wbstemplate = data => get(`/v1/crm/wbs/template?${qs.stringify(data)}`)